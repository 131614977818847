.blinking {
  border: 1px solid transparent;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
  100% {
    border-color: transparent;
  }
}
